import axios from 'axios';

import { useHistory } from 'react-router-dom';

import { User } from './Classes/User';




let baseUrl = "";
console.log('test env',process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === 'production') {
   baseUrl = "https://api.harusi.online/api";
}else{
   baseUrl = "https://staging.harusi.online/api";
}

let userDataString = localStorage.getItem('UserDataObject') || '';
let accessToken = "";

if(userDataString !== ''){

  let userData:User = JSON.parse(userDataString);
   accessToken = userData.token !== '' || userData.token !== null ? userData.token : '';

}

export const HarusiOnlinePaymentKey = () => {
  return 'FQC4AUVE2UTDNTVKX5';
}

export const GoogleApiClient = axios.create({
  baseURL:'https://people.googleapis.com/api/',
  headers:{"Content-Type": "application/json"}
});


GoogleApiClient.interceptors.request.use(request => {
  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})


GoogleApiClient.interceptors.response.use(response => {
  // console.log('Response:', JSON.stringify(response, null, 2));
  if(response.status === 401){
    const history = useHistory();
    const goLogin = () => history.push('login');
  
    goLogin();

    
  }
  return response
})




export const ExportClient = (url:string,uid:any) =>{
  let xhr = new XMLHttpRequest();
        xhr.open('GET' ,`${baseUrl}${url}`, true)
        xhr.setRequestHeader("Authorization", 'Bearer '+accessToken)
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        xhr.setRequestHeader("uid", uid)
        xhr.responseType = 'arraybuffer'
  return xhr;      
}

export const MobileApiClient = axios.create({
  baseURL:baseUrl
});


export const PaymentApiClient = axios.create({
  baseURL: 'https://payments.harusi.online/api/v1/client/',headers: {apptoken : HarusiOnlinePaymentKey()}
})


export const ApiClient = axios.create({
    baseURL:baseUrl,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });

  if (process.env.NODE_ENV !== 'production') {
  ApiClient.interceptors.request.use(request => {
    // console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
  })

  
  ApiClient.interceptors.response.use(response => {
    // console.log('Response:', JSON.stringify(response, null, 2))
    if(response.status === 401){
      const history = useHistory();
      const goLogin = () => history.push('login');
      goLogin(); 
    }
    return response
  })



  PaymentApiClient.interceptors.request.use(request => {
    console.log('Starting Payment Request', JSON.stringify(request, null, 2))
    return request
  })
  

  PaymentApiClient.interceptors.response.use(response => {
    console.log('Payment Response:', JSON.stringify(response, null, 2))
    return response
  })

  }




