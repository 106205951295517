import React from 'react'
import { CardHolder, CardImage, EventDate, EventTitle } from './Card.elements'
import harusi from '../../assets/images/harusi.jpg';
import { SpBadge } from '../SpPackageCard/PackageCard.elements';
import { Category } from '../../Data/Classes/Category';

type cardProps = {
    title?: string;
    date?: string;
    extras?: string;
    wallpaper_url?:string;
    isSp?:boolean;
    spCategory?:Category[];
    url:any;
    index:any;
}
const Card:React.FC<cardProps> = ({title,date,wallpaper_url,isSp,spCategory,url,index}) => {
    const eventClick = (url:string) =>{
        let fullUrl = "https://"+url+".harusi.online";
        window.open(fullUrl);
    }

    const spClick = (url:string):void => {
        let fullUrl = "https://www.harusi.online/dashboard/ecards/selectContacts/"+url;
        console.log("card template url",url)
        window.open(fullUrl);
    }

    function formatDate(date:any){
        if(date == null) date = Date()
        return new Date(date.replace(/-/g, "/")).toDateString()
    }  
   
    return (
        <CardHolder key={index} onClick={isSp? ()=>spClick(url) : ()=>eventClick(url)}>
            <CardImage src={wallpaper_url != null ? wallpaper_url : harusi}>
            </CardImage>
            {isSp &&(
               spCategory?.map((cat:any,ind)=>{
                // console.log("category",cat)
                   return(
                    <SpBadge key={ind}>#{cat.label?.toLowerCase()}</SpBadge>
                   )
               })
            )}           
            <EventTitle>
                {title}
            </EventTitle>
            <EventDate>
               {!isSp ? formatDate(date) : date?.toLocaleLowerCase()}
            </EventDate>
        </CardHolder>
       
    )
}

export default Card
